import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import productsConfigAggregator from './products/config-aggregator'
import ProductsShimmer from './products/products-shimmer'
import CategorySizeListShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/category-size-list/category-size-list-shimmer'
import BannerImageTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-text/banner-image-text-shimmer'
import BannerCarouselAlternateAppearanceShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types//banner-carousel-alternate-appearance/banner-carousel-alternate-appearance-shimmer'

const Products = dynamic(() => import('./products'), { loading: ProductsShimmer })
const productsConfig = { configAggregator: productsConfigAggregator, component: Products }

const BannerCarouselAlternateAppearance = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-carousel-alternate-appearance/banner-carousel-alternate-appearance'
		),
	{ loading: BannerCarouselAlternateAppearanceShimmer }
)

const BannerImageText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-text/banner-image-text'
		),
	{ loading: BannerImageTextShimmer }
)

const bannerImageTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerImageText,
}

const CategorySizeList = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/category-size-list/category-size-list'
		),
	{ loading: CategorySizeListShimmer }
)

const productsTileColorConfig = {
	...productsConfig,
	configAggregator: (node, props) => ({
		...productsConfigAggregator(node, props),
		tileVariant: 'color',
	}),
}

const productsCategorySizeListConfig = {
	...productsConfig,
	configAggregator: (node, props) => ({
		...productsConfigAggregator(node, props),
	}),
	component: CategorySizeList,
}

const productsCarouselAlternateAppearance = {
	configAggregator: productsConfigAggregator,
	component: BannerCarouselAlternateAppearance,
}
const productsCarouselAlternateAppearanceGridConfig = {
	...productsCarouselAlternateAppearance,
}
const productsCarouselAlternateAppearanceSliderConfig = {
	...productsCarouselAlternateAppearance,
}

const customContentTypes = {
	'banner-image-text': bannerImageTextConfig,
	'products-tile-color': productsTileColorConfig,
	'products-category-size-list': productsCategorySizeListConfig,
	'products-carousel-alternate-appearance-grid': productsCarouselAlternateAppearanceGridConfig,
	'products-carousel-alternate-appearance-slider': productsCarouselAlternateAppearanceSliderConfig,
}

export default customContentTypes
